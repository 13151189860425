<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-6">
          <v-toolbar color="primary" flat dark>
            <v-toolbar-title>Logg inn</v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-card-text>
            <v-form v-model="isValid" @keyup.native.enter="login()">
              <v-text-field
                v-model="email"
                label="Brukernavn"
                name="login"
                prepend-icon="person"
                type="email"
                :rules="[requiredRule('Epost', true), emailRule()]"
              />
              <v-text-field
                v-model="password"
                id="password"
                label="Passord"
                name="password"
                prepend-icon="lock"
                type="password"
                :rules="[requiredRule('Passord', true)]"
              />
              <v-btn color="primary" to="/reset-password" text small
                >Glemt passord?</v-btn
              >
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" :disabled="!isValid" @click="login()"
              >Logg inn</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { settings } from "@/settings";
import { mapGetters } from "vuex";
import validationRules from "@/validation/rules.js";

export default {
  name: "login",
  data: () => ({
    email: "",
    password: "",
    isValid: true
  }),
  methods: {
    login() {
      const { email, password } = this;
      this.$store
        .dispatch("auth/login", { email, password })
        .then(() => {
          this.$store.dispatch("user/get_profile").then(() => {
            if (this.isAdmin || this.isSuperAdmin) {
              this.$router.push("/admin");
            } else if (this.isCustomer) {
              this.$router.push("/profile");
            }
          });
        })
        .catch(error => {
          this.$store.dispatch("alert/show", {
            message: "Feil brukernavn eller passord",
            type: "error"
          });
        });
    },
    ...validationRules
  },
  computed: {
    ...mapGetters("user", ["isAdmin", "isSuperAdmin", "isCustomer"])
  }
};
</script>
