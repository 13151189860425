import { apiCall, api_routes } from "@/utils/api";

const state = {
  status: "",
  origins: {},
  error: ""
};

const getters = {
  getOrigins: state => state.destinations
};

const actions = {
  get({ commit }, request) {
    if (!request) {
      request = {
        page: "",
        query: "",
        itemsPerPage: -1,
        sortBy: "",
        sortDesc: ""
      };
    }
    return new Promise((resolve, reject) => {
      apiCall({
        url:
          api_routes.origin.get +
          "?page=" +
          request.page +
          "&query=" +
          request.query +
          "&sortBy=" +
          request.sortBy +
          "&sortDesc=" +
          request.sortDesc +
          "&itemsPerPage=" +
          request.itemsPerPage,
        method: "get"
      })
        .then(resp => {
          commit("get_origins_success", resp.data);
          resolve(resp.data);
        })
        .catch(err => {
          commit("admin_error", err);
          reject(err);
        });
    });
  },
  update({ commit }, request) {
    return new Promise((resolve, reject) => {
      apiCall({
        url: api_routes.origin.update + "/" + request.id,
        data: request,
        method: "put"
      })
        .then(resp => {
          commit("admin_success", resp);
          resolve(resp);
        })
        .catch(err => {
          commit("admin_error", err);
          reject(err);
        });
    });
  },
  delete({ commit }, request) {
    return new Promise((resolve, reject) => {
      apiCall({
        url: api_routes.origin.delete + "/" + request.id,
        data: request,
        method: "delete"
      })
        .then(resp => {
          commit("admin_success", resp);
          resolve(resp);
        })
        .catch(err => {
          commit("admin_error", err);
          reject(err);
        });
    });
  },
  create({ commit }, request) {
    return new Promise((resolve, reject) => {
      apiCall({
        url: api_routes.origin.create,
        data: request,
        method: "post"
      })
        .then(resp => {
          commit("admin_success", resp);
          resolve(resp);
        })
        .catch(err => {
          commit("admin_error", err);
          reject(err);
        });
    });
  }
};
const mutations = {
  get_origins_success(state, resp) {
    state.origins = resp.data;
  },
  admin_success(state, resp) {
    state.status = resp;
  },
  admin_error(state, err) {
    state.error = err;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
