<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :single-expand="true"
    :expanded.sync="expanded"
    item-key="id"
    show-expand
  >
    <template v-slot:expanded-item="{ headers, item }">
      <td class="pa-0" :colspan="headers.length">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th>Opprettet</th>
                <th>Beskrivelse</th>
                <th>Antall</th>
                <th class="text-end">Beløp</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="order_line in item.order_lines" :key="order_line.id">
                <td>{{ order_line.created_at | toDate }}</td>
                <td>{{ order_line.description }}</td>
                <td>{{ order_line.count }}</td>
                <td class="text-end">{{ order_line.amount }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </td>
    </template>
    <template v-slot:item.created_at="{ item }">
      {{ item.created_at | toDate }}
    </template>
  </v-data-table>
</template>
<script>
import moment from "moment";
import { settings } from "@/settings";

export default {
  props: {
    headers: Array,
    items: Array
  },
  data: () => ({
    expanded: []
  }),
  name: "orderstable",
  filters: {
    toDate: date => moment(date).format("YYYY-MM-DD")
  }
};
</script>
<style>
.mobile-stripes tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
  display: block !important;
}
</style>
