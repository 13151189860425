<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card class="elevation-1 mt-4">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title>
              {{ getProfile.provider.company_name }}
            </v-toolbar-title>
          </v-toolbar>
          <v-tabs v-model="tab">
            <v-tab>Kunder</v-tab>
            <v-tab>Alle Nummer</v-tab>
            <v-tab>Alle månedlige trekk</v-tab>
            <v-tab>Produkter</v-tab>
            <v-tab>Ledige Nummerserier</v-tab>
            <v-tab>Nummergrupper</v-tab>
            <v-tab>Betalingstyper</v-tab>
            <v-tab>Meldinger</v-tab>
            <v-tab>Kampanjer</v-tab>
            <v-tab>Destinasjoner</v-tab>
            <v-tab v-if="isAdmin">TSIP Rapport</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" touchless>
            <v-tab-item :eager="true">
              <data-table
                :headers="getCustomerHeaders()"
                :get_action="`customer/get`"
                :update_action="`customer/update`"
                :create_action="`customer/create`"
                :delete_action="`customer/delete`"
                :router_link="true"
                :singular="`kunde`"
                :show_create_user="true"
              ></data-table>
            </v-tab-item>
            <v-tab-item :eager="true">
              <data-table
                :headers="getNumberHeaders()"
                :get_action="`number/get`"
                :update_action="`number/update`"
                :create_action="`number/create`"
                :delete_action="`number/delete`"
                :singular="`nummer`"
                :create_info="'Nummeret vil også opprettes i TSIP'"
                :show_service_groups_select="true"
                parent_name="Servicegruppe i TSIP"
              ></data-table>
            </v-tab-item>
            <v-tab-item :eager="true">
              <data-table
                :headers="getSubscriptionHeaders()"
                :get_action="`subscription/get`"
                :update_action="`subscription/update`"
                :create_action="`subscription/create`"
                :delete_action="`subscription/delete`"
                parent_name="Produkt"
                ref="subscriptions"
                :singular="`månedlig trekk`"
                :show_products_select="true"
                :show_date_filters="true"
              ></data-table>
            </v-tab-item>
            <v-tab-item :eager="true">
              <data-table
                :headers="getProductHeaders()"
                :get_action="`product/get`"
                :update_action="`product/update`"
                :create_action="`product/create`"
                :delete_action="`product/delete`"
                :singular="`produkt`"
              ></data-table>
            </v-tab-item>
            <v-tab-item :eager="true">
              <data-table
                :headers="getAllNumbersHeaders()"
                :get_action="`tsip/get_all_numbers`"
                :singular="`Nummerserie`"
              ></data-table>
            </v-tab-item>
            <v-tab-item :eager="true">
              <data-table
                :headers="getNumberGroupHeaders()"
                :get_action="`number_group/get`"
                :update_action="`number_group/update`"
                :create_action="`number_group/create`"
                :delete_action="`number_group/delete`"
                :singular="`nummergruppe`"
                parent_name="Nummergruppe"
              >
              </data-table>
            </v-tab-item>
            <v-tab-item :eager="true">
              <data-table
                :headers="getPaymentTypeHeaders()"
                :get_action="`payment_type/get`"
                :update_action="`payment_type/update`"
                :create_action="`payment_type/create`"
                :delete_action="`payment_type/delete`"
                :singular="`betalingstype`"
                parent_name="Betalingstype"
              >
              </data-table>
            </v-tab-item>
            <v-tab-item :eager="true">
              <data-table
                :headers="getMessageHeaders()"
                :get_action="`message/get`"
                :update_action="`message/update`"
                :create_action="`message/create`"
                :delete_action="`message/delete`"
                :singular="`melding`"
                parent_name="Melding"
              >
              </data-table>
            </v-tab-item>
            <v-tab-item :eager="true">
              <data-table
                :headers="getCampaignHeaders()"
                :get_action="`campaign/get`"
                :update_action="`campaign/update`"
                :create_action="`campaign/create`"
                :delete_action="`campaign/delete`"
                :singular="`kampanje`"
                parent_name="Kampanje"
              ></data-table>
            </v-tab-item>
            <v-tab-item :eager="true">
              <data-table
                :headers="getDestinationHeaders()"
                :get_action="`destination/get`"
                :update_action="`destination/update`"
                :create_action="`destination/create`"
                :delete_action="`destination/delete`"
                :singular="`destinasjon`"
              ></data-table>
            </v-tab-item>
            <v-tab-item :eager="true">
              <tsip-report :is_super_admin="false"></tsip-report>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { settings } from "@/settings";
import { mapGetters } from "vuex";

export default {
  data: () => ({
    tab: null,
  }),
  name: "admin",
  available_payment_types: {},
  all_number_groups: {},
  all_campaigns: {},
  methods: {
    getSubscriptionHeaders() {
      return [
        {
          text: "ID",
          align: "start",
          sortable: true,
          value: "id",
          updatable: false,
        },
        {
          text: "Opprettet",
          align: "start",
          sortable: true,
          value: "created_at",
        },
        {
          text: "Eier",
          value: "department.name",
          sortable: true,
          updatable: true,
          type: "select",
          item_text: "name",
          item_value: "id",
          items: this.getDepartments,
          parent: "department",
          parent_id: "department_id",
          clearable: true,
        },
        {
          text: "Produkt",
          value: "product_id",
          sortable: true,
          updatable: true,
          type: "select",
          item_text: "description",
          item_value: "id",
          items: this.getAllProducts,
          parent_id: "product_id",
          clearable: true,
        },
        {
          text: "Forskuddsbetalt",
          value: "is_prepaid",
          updatable: true,
          align: "center",
          type: "boolean",
        },
        {
          text: "Annonse",
          value: "is_advertisement",
          updatable: true,
          align: "center",
          type: "boolean",
        },
        {
          text: "Beskrivelse",
          value: "description",
          updatable: true,
          required: true,
          type: "string",
        },
        {
          text: "Antall",
          value: "count",
          updatable: true,
          required: true,
          type: "string",
        },
        {
          text: "Start-dato",
          value: "start_date",
          updatable: true,
          required: true,
          type: "date",
        },
        {
          text: "Slutt-dato",
          value: "end_date",
          updatable: true,
          required: false,
          clearable: true,
          type: "date",
        },
        {
          text: "Beløp",
          value: "amount",
          updatable: true,
          required: true,
          type: "amount",
        },
        {
          text: "Handling",
          value: "actions",
          sortable: false,
          updatable: false,
          align: "end",
        },
      ];
    },
    getCustomerHeaders() {
      return [
        {
          text: "ID",
          align: "start",
          sortable: true,
          value: "id",
          updatable: false,
        },
        {
          text: "Opprettet",
          align: "start",
          sortable: true,
          value: "created_at",
        },
        {
          text: "Navn",
          value: "customer_name",
          updatable: true,
          required: true,
          type: "string",
        },
        {
          text: "Orgnummer",
          value: "organization_number",
          updatable: true,
          required: false,
          type: "orgnumber",
        },
        {
          text: "Epost",
          value: "email",
          updatable: true,
          required: true,
          type: "email",
        },
        {
          text: "Produkt for provisjon",
          value: "incoming_product_id",
          sortable: false,
          updatable: true,
          type: "select",
          item_text: "description",
          item_value: "id",
          items: this.getAllProducts,
          parent_id: "incoming_product_id",
          clearable: true,
          align: " d-none",
        },
        {
          text: "Produkt for trafikk-kostnader",
          value: "outgoing_product_id",
          sortable: false,
          updatable: true,
          type: "select",
          item_text: "description",
          item_value: "id",
          items: this.getAllProducts,
          parent_id: "outgoing_product_id",
          clearable: true,
          align: " d-none",
        },
        {
          text: "Kvartalsvis fakturering",
          value: "quarterly_billing",
          updatable: true,
          align: "center",
          type: "boolean",
        },
        { text: "Handling", value: "actions", sortable: false, align: "end" },
      ];
    },
    getDestinationHeaders() {
      return [
        {
          text: "ID",
          align: "start",
          sortable: true,
          value: "id",
          updatable: false,
        },
        {
          text: "Opprettet",
          align: "start",
          sortable: true,
          value: "created_at",
        },
        {
          text: "Beskrivelse",
          value: "description",
          updatable: true,
          required: true,
          type: "string",
        },
        {
          text: "Prefix",
          value: "number_prefix",
          updatable: true,
          required: true,
          type: "string",
        },
        {
          text: "Pris/min",
          value: "price_per_minute",
          updatable: true,
          required: true,
          type: "amount",
        },
        {
          text: "Pris/samtale",
          value: "price_per_call",
          updatable: true,
          required: true,
          type: "amount",
        },

        {
          text: "Handling",
          value: "actions",
          sortable: false,
          updatable: false,
          align: "end",
        },
      ];
    },
    getProductHeaders() {
      return [
        {
          text: "ID",
          align: "start",
          sortable: true,
          value: "id",
          updatable: false,
        },
        {
          text: "Opprettet",
          align: "start",
          sortable: true,
          value: "created_at",
        },
        {
          text: "Beskrivelse",
          value: "description",
          updatable: true,
          required: true,
          type: "string",
        },
        {
          text: "Beløp",
          value: "amount",
          updatable: true,
          required: true,
          type: "amount",
        },
        {
          text: "Inkommende trafikk",
          value: "is_incoming_traffic",
          updatable: true,
          align: "center",
          type: "boolean",
        },
        {
          text: "Utgående trafikk",
          value: "is_outgoing_traffic",
          updatable: true,
          align: "center",
          type: "boolean",
        },
        {
          text: "Kalkuler MVA",
          value: "calc_vat",
          updatable: true,
          align: "center",
          type: "boolean",
        },
        {
          text: "Handling",
          value: "actions",
          sortable: false,
          updatable: false,
          align: "end",
        },
      ];
    },
    getNumberHeaders() {
      return [
        {
          text: "ID",
          align: "start",
          sortable: true,
          value: "id",
          updatable: false,
        },
        {
          text: "Opprettet",
          sortable: true,
          value: "created_at",
          updatable: false,
        },
        {
          text: "Eier",
          value: "department.name",
          sortable: true,
          updatable: true,
          type: "select",
          item_text: "name",
          item_value: "id",
          items: this.getDepartments,
          parent: "department",
          parent_id: "department_id",
          clearable: true,
        },
        {
          text: "Nummer",
          value: "number",
          updatable: true,
          required: true,
          type: "phone",
        },
        {
          text: "Beskrivelse",
          value: "description",
          updatable: true,
          required: true,
          type: "string",
        },
        {
          text: "ServiceGruppe i TSIP",
          value: "servicegroup_id",
          sortable: false,
          updatable: true,
          type: "select",
          item_text: "Description",
          item_value: "SG_ID",
          items: this.getServiceGroups,
          parent_id: "servicegroup_id",
          align: " d-none",
        },
        {
          text: "Kategori i TSIP",
          value: "servicecategory_id",
          sortable: false,
          updatable: true,
          type: "select",
          item_text: "ServiceCategoryName",
          item_value: "SC_ID",
          items: this.getServiceCategories,
          parent_id: "servicecategory_id",
          align: " d-none",
        },
        {
          text: "Provisjon/min",
          value: "provision_per_minute",
          updatable: true,
          required: true,
          type: "amount",
        },
        {
          text: "Provisjon/samtale",
          value: "provision_per_call",
          updatable: true,
          required: true,
          type: "amount",
        },
        {
          text: "Pris/min mobil",
          value: "price_per_minute_mobile",
          updatable: true,
          required: true,
          type: "amount",
        },
        {
          text: "Pris/samtale mobil",
          value: "price_per_call_mobile",
          updatable: true,
          required: true,
          type: "amount",
        },
        {
          text: "Avgift/min",
          value: "cost_per_minute",
          updatable: true,
          required: true,
          type: "amount",
        },
        {
          text: "Avgift/samtale",
          value: "cost_per_call",
          updatable: true,
          required: true,
          type: "amount",
        },
        {
          text: "NummerGruppe",
          value: "number_group.description",
          sortable: false,
          updatable: true,
          type: "select",
          item_text: "description",
          item_value: "id",
          items: this.all_number_groups,
          parent: "number_group",
          parent_id: "number_group_id",
        },
        {
          text: "Provider",
          value: "provider.company_name",
          updatable: true,
          required: false,
          type: "select",
          item_text: "company_name",
          item_value: "id",
          parent: "provider",
          parent_id: "provider_id",
          items: this.getProviders,
        },
        {
          text: "Meldinger",
          value: "messages",
          updatable: true,
          required: false,
          sortable: false,
          type: "multiselect",
          item_text: "description",
          item_value: "id",
          items: this.getMessages,
        },
        {
          text: "Start-dato",
          value: "start_date",
          updatable: true,
          required: true,
          type: "date",
        },
        {
          text: "Slutt-dato",
          value: "end_date",
          updatable: true,
          clearable: true,
          type: "date",
        },
        {
          text: "Fryst-dato",
          value: "frozen_date",
          updatable: true,
          required: false,
          clearable: true,
          type: "date",
        },
        {
          text: "Handling",
          value: "actions",
          sortable: false,
          updatable: false,
          align: "end",
        },
      ];
    },
    getNumberGroupHeaders() {
      return [
        {
          text: "ID",
          align: "start",
          sortable: true,
          value: "id",
          updatable: false,
        },
        {
          text: "Opprettet",
          align: "start",
          sortable: true,
          value: "created_at",
          updatable: false,
        },
        {
          text: "Beskrivelse",
          value: "description",
          updatable: true,
          required: true,
          type: "string",
        },
        {
          text: "Dropcharge",
          value: "is_dropcharge",
          updatable: true,
          required: false,
          align: "center",
          type: "boolean",
        },
        {
          text: "Charge Grace Time",
          value: "charge_grace_time",
          updatable: true,
          required: true,
          type: "string",
        },
        {
          text: "Ekstern Tjenestekode",
          value: "external_service_code",
          updatable: true,
          required: false,
          type: "string",
        },
        {
          text: "Betalingstyper",
          value: "payment_types",
          updatable: true,
          required: false,
          sortable: false,
          type: "multiselect",
          item_text: "description",
          item_value: "id",
          items: this.available_payment_types,
        },
        {
          text: "Provider",
          value: "provider.company_name",
          updatable: true,
          required: false,
          type: "select",
          item_text: "company_name",
          item_value: "id",
          parent: "provider",
          parent_id: "provider_id",
          items: this.getProviders,
        },
        {
          text: "Kampanje",
          value: "campaign.description",
          sortable: false,
          updatable: true,
          clearable: true,
          type: "select",
          item_text: "description",
          item_value: "id",
          items: this.getCampaigns,
          parent: "campaign",
          parent_id: "campaign_id",
        },
        {
          text: "Meldinger",
          value: "messages",
          updatable: true,
          required: false,
          sortable: false,
          type: "multiselect",
          item_text: "description",
          item_value: "id",
          items: this.getMessages,
        },
        {
          text: "Handling",
          value: "actions",
          sortable: false,
          updatable: false,
          align: "end",
        },
      ];
    },
    getPaymentTypeHeaders() {
      return [
        {
          text: "ID",
          align: "start",
          sortable: true,
          value: "id",
          updatable: false,
        },
        {
          text: "Opprettet",
          align: "start",
          sortable: true,
          value: "created_at",
          updatable: false,
        },
        {
          text: "Beskrivelse",
          value: "description",
          updatable: true,
          required: true,
          type: "string",
        },
        {
          text: "Pris/min",
          value: "price_per_minute",
          updatable: true,
          required: false,
          type: "amount",
        },
        {
          text: "Pris/samtale",
          value: "price_per_call",
          updatable: true,
          required: false,
          type: "amount",
        },
        {
          text: "Charge Tidspunkt",
          value: "charge_moment.description",
          updatable: true,
          required: false,
          clearable: true,
          type: "select",
          item_text: "description",
          item_value: "id",
          parent: "charge_moment",
          parent_id: "charge_moment_id",
          items: this.getChargeMoments,
        },
        {
          text: "Charge Strategi",
          value: "charge_strategy.description",
          updatable: true,
          required: false,
          clearable: true,
          type: "select",
          item_text: "description",
          item_value: "id",
          parent: "charge_strategy",
          parent_id: "charge_strategy_id",
          items: this.getChargeStrategies,
        },
        {
          text: "For VIP kunder",
          value: "is_vip",
          updatable: true,
          required: false,
          align: "center",
          type: "boolean",
        },
        {
          text: "Prioritet",
          value: "priority",
          updatable: true,
          required: false,
          type: "string",
        },
        {
          text: "Provider",
          value: "provider.company_name",
          updatable: true,
          required: false,
          type: "select",
          item_text: "company_name",
          item_value: "id",
          parent: "provider",
          parent_id: "provider_id",
          items: this.getProviders,
        },
        {
          text: "Betalingsløsning",
          value: "supported_payment_option.description",
          sortable: false,
          updatable: true,
          clearable: true,
          type: "select",
          item_text: "description",
          item_value: "id",
          items: this.getSupportedPaymentOptions,
          parent: "supported_payment_option",
          parent_id: "supported_payment_option_id",
        },
        {
          text: "Handling",
          value: "actions",
          sortable: false,
          updatable: false,
          align: "end",
        },
      ];
    },
    getMessageHeaders() {
      return [
        {
          text: "ID",
          align: "start",
          sortable: true,
          value: "id",
          updatable: false,
        },
        {
          text: "Opprettet",
          sortable: true,
          value: "created_at",
        },
        {
          text: "Beskrivelse",
          value: "description",
          updatable: true,
          required: true,
          type: "string",
        },
        {
          text: "Melding",
          value: "message",
          updatable: true,
          required: true,
          type: "textfield",
        },
        {
          text: "SSML",
          value: "ssml",
          updatable: true,
          required: true,
          type: "textfield",
        },
        {
          text: "Start-dato",
          value: "start_date",
          updatable: true,
          required: true,
          type: "date",
        },
        {
          text: "Slutt-dato",
          value: "end_date",
          updatable: true,
          required: true,
          type: "date",
        },
        {
          text: "Handling",
          value: "actions",
          sortable: false,
          updatable: false,
          align: "end",
        },
      ];
    },
    getCampaignHeaders() {
      return [
        {
          text: "ID",
          align: "start",
          sortable: true,
          value: "id",
          updatable: false,
        },
        {
          text: "Opprettet",
          align: "start",
          sortable: true,
          value: "created_at",
          updatable: false,
        },
        {
          text: "Beskrivelse",
          value: "description",
          updatable: true,
          required: true,
          type: "string",
        },
        {
          text: "Gruppekampanje",
          value: "is_group_campaign",
          updatable: true,
          required: false,
          type: "boolean",
        },
        {
          text: "Prosent avslag",
          value: "percent_off",
          updatable: true,
          required: false,
          type: "string",
        },
        {
          text: "Gratis samtaler",
          value: "free_calls",
          updatable: true,
          required: false,
          type: "string",
        },
        {
          text: "Gratis ringeminutter",
          value: "free_trial_time",
          updatable: true,
          required: false,
          type: "string",
        },
        {
          text: "Provider",
          value: "provider.company_name",
          updatable: true,
          required: false,
          type: "select",
          item_text: "company_name",
          item_value: "id",
          parent: "provider",
          parent_id: "provider_id",
          items: this.getProviders,
        },
        {
          text: "Meldinger",
          value: "messages",
          updatable: true,
          required: false,
          sortable: false,
          type: "multiselect",
          item_text: "description",
          item_value: "id",
          items: this.getMessages,
        },
        {
          text: "Start-dato",
          value: "start_date",
          updatable: true,
          required: true,
          type: "date",
        },
        {
          text: "Slutt-dato",
          value: "end_date",
          updatable: true,
          required: false,
          clearable: true,
          type: "date",
        },
        {
          text: "Handling",
          value: "actions",
          sortable: false,
          updatable: false,
          align: "end",
        },
      ];
    },
    getAllNumbersHeaders() {
      return [
        {
          text: "Nummer",
          value: "Number",
          type: "string",
        },
        {
          text: "Nåverende Operatør",
          value: "CurrentProvider",
          type: "string",
        },
        {
          text: "Forrige Operatør",
          value: "LastProvider",
          type: "string",
        },
        {
          text: "Opprinnelig Operatør",
          value: "OriginalProvider",
          type: "string",
        },
        {
          text: "Sist endret",
          value: "LastChangeDate",
          type: "date",
        },
      ];
    },
  },
  computed: {
    ...mapGetters("user", ["getProfile", "isAdmin"]),
    ...mapGetters("department", ["getDepartments"]),
    ...mapGetters("tsip", ["getServiceCategories", "getServiceGroups"]),
    ...mapGetters("product", ["getAllProducts"]),
    ...mapGetters("number_group", ["getNumberGroups"]),
    ...mapGetters("campaign", ["getCampaigns"]),
    ...mapGetters("supported_payment_option", ["getSupportedPaymentOptions"]),
    ...mapGetters("charge_strategy", ["getChargeStrategies"]),
    ...mapGetters("charge_moment", ["getChargeMoments"]),
    ...mapGetters("provider", ["getProviders"]),
    ...mapGetters("payment_type", ["getPaymentTypes"]),
    ...mapGetters("message", ["getMessages"]),
  },
  mounted() {
    this.$store.dispatch("department/get").catch((err) => {
      this.$store.dispatch("alert/show", {
        message: `Feil i provider/get: ${err.message}`,
        type: "error",
      });
    });
    this.$store.dispatch("provider/get");
    this.$store
      .dispatch("payment_type/get", {
        page: "",
        query: "",
        itemsPerPage: -1,
        sortBy: "",
        sortDesc: "",
      })
      .then((res) => {
        this.available_payment_types = res.data;
      });
    this.$store
      .dispatch("number_group/get", {
        page: "",
        query: "",
        itemsPerPage: -1,
        sortBy: "",
        sortDesc: "",
      })
      .then((res) => {
        this.all_number_groups = res.data;
      });
    this.$store
      .dispatch("campaign/get", {
        page: "",
        query: "",
        itemsPerPage: -1,
        sortBy: "",
        sortDesc: "",
      })
      .then((res) => {
        this.all_campaigns = res.data;
      });
    this.$store.dispatch("supported_payment_option/get");
    this.$store.dispatch("charge_strategy/get");
    this.$store.dispatch("charge_moment/get");
    this.$store.dispatch("tsip/get_service_categories");
    this.$store.dispatch("product/get_all");
  },
};
</script>
