import PhoneNumber from "awesome-phonenumber";
import store from "../store";

export default {
  emailRule() {
    return v => {
      if (v) {
        return this.validateEmail(v) || "Epost er ikke gyldig";
      }
      return true;
    };
  },
  phoneRule() {
    return v => {
      if (v) {
        return new PhoneNumber("+47" + v).isValid() || "Ugyldig Telefonnummer";
      }
      return true;
    };
  },
  orgNumberRule() {
    return v => {
      if (v) {
        return this.validateOrgNumber(v) || "Orgnummer er ikke gyldig";
      }
      return true;
    };
  },
  amountRule(header) {
    return v => {
      if (v) {
        return this.isNumeric(v) || header.text + " må være et tall";
      }
      return true;
    };
  },
  requiredRule(name, required) {
    if (required) {
      return v => !!v || name + " nødvendig";
    }
    return true;
  },
  passwordLengthRule(length) {
    return v => {
      if (v) {
        return v.length >= length || "Passord må inneholde minst 8 tegn";
      }
      return true;
    };
  },
  validateOrgNumber(number) {
    const w = [3, 2, 7, 6, 5, 4, 3, 2]; /* Brreg weights for orgnumbers */
    if (number) {
      let tempk = 0;
      for (let i = 0; i < number.length - 1; i++) {
        tempk += number[i] * w[i];
      }
      tempk = 11 - (tempk % 11);
      if (tempk === 11) tempk = 0;
      if (tempk !== parseInt(number[number.length - 1], 10)) return false;
    }
    return true;
  },
  validateEmail(email) {
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(
      email
    );
  },
  isNumeric(input) {
    return input - 0 == input && ("" + input).trim().length > 0;
  }
};
