import { apiCall, api_routes } from "@/utils/api";

const state = {
  status: "",
  orders: {},
  error: ""
};

const getters = {
  getOrders: state => state.orders.data
};

const actions = {
  get({ commit }, request) {
    if (!request) {
      request = {
        page: "",
        query: "",
        itemsPerPage: -1,
        sortBy: "",
        sortDesc: ""
      };
    }
    return new Promise((resolve, reject) => {
      apiCall({
        url:
          api_routes.order.get +
          "/" +
          request.parent_id +
          "?page=" +
          request.page +
          "&query=" +
          request.query +
          "&sortBy=" +
          request.sortBy +
          "&sortDesc=" +
          request.sortDesc +
          "&itemsPerPage=" +
          request.itemsPerPage,
        method: "get"
      })
        .then(resp => {
          commit("get_orders_success", resp.data);
          resolve(resp.data);
        })
        .catch(err => {
          commit("admin_error", err);
          reject(err);
        });
    });
  }
};
const mutations = {
  get_orders_success(state, resp) {
    state.orders = resp.data;
  },
  admin_success(state, resp) {
    state.status = resp;
  },
  admin_error(state, err) {
    state.error = err;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
