<template>
  <span>
    <v-row>
      <v-col>
        <v-alert
          v-if="create_info && !editing"
          type="info"
          outlined
          color="primary"
        >
          {{ create_info }}
        </v-alert>
        <div v-for="header in headers" :key="header.value">
          <v-text-field
            v-if="header.updatable && header.type === 'string'"
            v-model="edited_item[header.value]"
            :label="header.text"
            :rules="[requiredRule(header.text, header.required)]"
          ></v-text-field>

          <v-text-field
            v-if="header.updatable && header.type === 'amount'"
            v-model="edited_item[header.value]"
            :label="header.text"
            :rules="[
              amountRule(header),
              requiredRule(header.text, header.required)
            ]"
            error-count="2"
          ></v-text-field>
          <v-text-field
            v-if="header.updatable && header.type === 'email'"
            v-model="edited_item[header.value]"
            :label="header.text"
            :rules="[emailRule(), requiredRule(header.text, header.required)]"
            error-count="2"
            type="email"
          ></v-text-field>
          <v-text-field
            :error-messages="errors"
            v-if="header.updatable && header.type === 'phone'"
            v-model="edited_item[header.value]"
            :label="header.text"
            :rules="[phoneRule(), requiredRule(header.text, header.required)]"
            error-count="2"
          ></v-text-field>
          <v-text-field
            v-if="header.updatable && header.type === 'orgnumber'"
            v-model="edited_item[header.value]"
            :label="header.text"
            :rules="[
              orgNumberRule(),
              requiredRule(header.text, header.required)
            ]"
          ></v-text-field>
          <v-textarea
            v-if="header.updatable && header.type === 'textfield'"
            v-model="edited_item[header.value]"
            :label="header.text"
            :rules="[requiredRule(header.text, header.required)]"
          ></v-textarea>
          <v-checkbox
            v-if="header.updatable && header.type === 'boolean'"
            v-model="edited_item[header.value]"
            :label="header.text"
          ></v-checkbox>
          <v-select
            v-if="header.updatable && header.type === 'select'"
            :item-text="header.item_text"
            :item-value="header.item_value"
            v-model="edited_item[header.parent_id]"
            :items="header.items"
            :label="header.text"
            :clearable="header.clearable"
            v-on:click:clear="edited_item[header.parent] = null"
            v-on:change="selectUpdated(header, edited_item[header.parent_id])"
          >
          </v-select>
          <v-text-field
            v-if="header.updatable && header.type === 'date'"
            type="date"
            v-model="edited_item[header.value]"
            :label="header.text"
            :rules="[requiredRule(header.text, header.required)]"
            :clearable="edited_item[header.value] && header.clearable"
          ></v-text-field>
          <v-select
            v-if="header.type === 'multiselect' && header.updatable"
            v-model="edited_item[header.value]"
            :items="header.items"
            :item-text="header.item_text"
            :item-value="header.item_value"
            :menu-props="{ maxHeight: '400' }"
            :label="header.text"
            multiple
          ></v-select>
        </div>
        <div v-if="change_password">
          <v-checkbox
            v-model="toggle_change_password"
            label="Endre passord"
          ></v-checkbox>
          <v-text-field
            type="password"
            v-if="toggle_change_password"
            v-model="edited_item['password']"
            label="Passord"
            prepend-icon="lock"
            :rules="[requiredRule('Passord', true), passwordLengthRule(8)]"
            error-count="2"
          ></v-text-field>
          <v-text-field
            type="password"
            v-if="toggle_change_password"
            v-model="edited_item['confirm_password']"
            label="Bekreft passord"
            prepend-icon="lock"
            :rules="[
              requiredRule('Bekreft passord', true),
              v => v === edited_item.password || 'Passordet matcher ikke'
            ]"
          ></v-text-field>
        </div>
        <div v-if="show_create_user && !editing">
          <v-checkbox
            v-model="edited_item['create_user']"
            label="Opprett bruker samtidig"
          ></v-checkbox>
        </div>
      </v-col>
    </v-row>
  </span>
</template>

<script>
import validationRules from "@/validation/rules.js";

export default {
  props: {
    headers: Array,
    edited_item: Object,
    change_password: Boolean,
    show_create_user: Boolean,
    show_products_select: Boolean,
    editing: Boolean,
    create_info: String
  },
  data() {
    return {
      menu: false,
      modal: false,
      menu2: false,
      errors: [],
      toggle_change_password: false
    };
  },
  methods: {
    selectUpdated(header, item_id) {
      if (this.show_products_select) {
        for (let product of header.items) {
          if (product.id === item_id) {
            this.edited_item.description = product.description;
            this.edited_item.amount = product.amount;
          }
        }
      }
    },
    ...validationRules
  },
  computed: {
    periodInput: {
      get: function() {
        return this.period;
      },
      set: function(period) {
        this.$emit("update:period", period);
      }
    }
  },
  watch: {
    // Vuetify doesn't support async rules yet, so we have to do it here instead of in the validation file
    edited_item: {
      handler(val) {
        if (val.number && val.number.length === 8) {
          this.$store
            .dispatch("tsip/get_number_status", { number: val.number })
            .then(res => {
              if (!res.data.available && !res.data.in_use) {
                this.errors = ["Nummeret eksisterer ikke i våre nummerserier"];
              }
              if (res.data.available && res.data.in_use && !this.editing) {
                this.errors = ["Nummeret er alerede i bruk"];
              }
            });
          this.errors = [];
        } else {
          this.errors = [];
        }
      },
      deep: true
    }
  }
};
</script>
