import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import DataTable from "@/components/globals/DataTable";
import DataTableHeaders from "@/components/globals/DataTableHeaders";
import TsipReport from "@/components/globals/TsipReport";
import OrdersTable from "@/components/globals/OrdersTable";
import NavBar from "@/components/globals/NavBar";
import DatePicker from "@/components/globals/DatePicker";

Vue.config.productionTip = false;
window.Event = new Vue();

Vue.component("data-table", DataTable);
Vue.component("data-table-headers", DataTableHeaders);
Vue.component("orders-table", OrdersTable);
Vue.component("tsip-report", TsipReport);
Vue.component("nav-bar", NavBar);
Vue.component("date-picker", DatePicker);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
