<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :loading="loading"
    :options.sync="options"
    :server-items-length="total"
    v-model="selected"
    :show-select="show_select && total > 1"
    :single-select="true"
    @input="get_department_id"
    @click:row="routeTo"
    :class="{
      'tf-pointer': router_link,
      'mobile-stripes': $vuetify.breakpoint.xs,
    }"
    :item-class="getRowClasses"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-text-field
          v-if="total > 10 || options.query !== ''"
          v-model="options.query"
          append-icon="mdi-magnify"
          label="Søk"
          single-line
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>

        <date-picker
          v-if="show_date_filters"
          :period.sync="options.period"
          clearable
        >
        </date-picker>
        <v-spacer></v-spacer>

        <v-checkbox
          v-if="show_date_filters"
          v-model="options.is_advertisement"
          label="Kun annonser"
          hide-details
        ></v-checkbox>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-if="create_action" v-slot:activator="{ on }">
            <v-btn color="primary" dark v-on="on" @click="onCreate()">
              <v-icon>mdi-plus</v-icon>
              {{ singular }}
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-form v-model="is_valid">
                <data-table-headers
                  :headers="headers"
                  :edited_item.sync="edited_item"
                  :change_password="change_password"
                  :show_create_user="show_create_user"
                  :editing="editing"
                  :create_info="create_info"
                  :show_products_select="show_products_select"
                ></data-table-headers>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="close">Avbryt</v-btn>
              <v-btn color="primary" text @click="save" :disabled="!is_valid"
                >Lagre</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:item.actions="{ item }">
      <v-icon
        v-if="update_action"
        small
        class="mr-2"
        @click.stop="editItem(item)"
        >mdi-pencil</v-icon
      >
      <v-icon v-if="delete_action" small @click.stop="deleteItem(item)"
        >mdi-delete</v-icon
      >
    </template>
    <template v-slot:item.calc_vat="{ item }">
      <v-icon v-if="item.calc_vat" small>mdi-check</v-icon>
    </template>
    <template v-slot:item.is_incoming_traffic="{ item }">
      <v-icon v-if="item.is_incoming_traffic" small>mdi-check</v-icon>
    </template>
    <template v-slot:item.is_outgoing_traffic="{ item }">
      <v-icon v-if="item.is_outgoing_traffic" small>mdi-check</v-icon>
    </template>
    <template v-slot:item.calc_provision_vat="{ item }">
      <v-icon v-if="item.calc_provision_vat" small>mdi-check</v-icon>
    </template>
    <template v-slot:item.is_prepaid="{ item }">
      <v-icon v-if="item.is_prepaid" small>mdi-check</v-icon>
    </template>
    <template v-slot:item.is_advertisement="{ item }">
      <v-icon v-if="item.is_advertisement" small>mdi-check</v-icon>
    </template>
    <template v-slot:item.quarterly_billing="{ item }">
      <v-icon v-if="item.quarterly_billing" small>mdi-check</v-icon>
    </template>
    <template v-slot:item.department.name="{ item }">
      <router-link
        :to="{
          name: 'edit-customer',
          params: { id: item.department.customer_id },
        }"
      >
        {{ item.department.name }}
      </router-link>
    </template>
    <template v-slot:item.product_id="{ item }">
      {{
        item.product && item.product.description ? item.product.description : ""
      }}
    </template>
    <template v-slot:item.created_at="{ item }">
      {{ item.created_at | toDate }}
    </template>
    <template v-slot:item.roles="{ item }">
      {{ item.roles.map((e) => e.name).join(", ") }}
    </template>
    <template v-slot:item.messages="{ item }">
      {{ item.messages.map((e) => e.description).join(", ") }}
    </template>
    <template v-slot:item.payment_types="{ item }">
      {{ item.payment_types.map((e) => e.description).join(", ") }}
    </template>
  </v-data-table>
</template>
<script>
import moment from "moment";

export default {
  props: {
    headers: Array,
    get_action: String,
    update_action: String,
    create_action: String,
    delete_action: String,
    router_link: Boolean,
    show_select: Boolean,
    show_create_user: Boolean,
    parent_name: String,
    parent_id: Number,
    get_department_id: Function,
    title: String,
    singular: String,
    change_password: Boolean,
    show_products_select: Boolean,
    show_service_groups_select: Boolean,
    show_date_filters: Boolean,
    create_info: String,
  },
  data: () => ({
    total: 0,
    items: [],
    selected: [],
    service_groups: [],
    errors: [],
    edited_item: {
      create_user: true,
    },
    options: {
      query: "",
      period: [],
      is_advertisement: "",
    },
    dialog: false,
    editing: false,
    loading: true,
    is_valid: true,
    from: null,
    to: null,
    is_advertisement: null,
  }),
  name: "datatable",
  methods: {
    getResults() {
      this.loading = true;
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.$store
          .dispatch(this.get_action, {
            parent_id: this.parent_id,
            ...this.options,
          })
          .then((res) => {
            this.items = res.data;
            this.total = res.total;
            if (this.show_select && this.selected.length == 0) {
              this.selected.push(this.items[0]);
            }
          })
          .catch((err) => {
            this.$store.dispatch("alert/show", {
              message: `Feil i ${this.get_action}: ${err.message}`,
              type: "error",
            });
          })
          .finally(() => {
            this.loading = false;
          });
      }, 200);
    },
    onCreate() {
      this.toggle_change_password = true;
      if (this.show_service_groups_select) {
        this.getServiceGroupsById(this.parent_id);
      }
    },
    editItem(item) {
      this.edited_item = Object.assign({}, item);
      if (this.show_service_groups_select) {
        this.getServiceGroupsById(item.department_id);
      }
      this.dialog = true;
      this.editing = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editing = false;
        this.toggle_change_password = false;
        this.edited_item = { create_user: true };
      });
    },
    save() {
      if (this.editing) {
        this.updateItem();
      } else {
        this.createItem();
      }
      this.close();
    },
    updateItem() {
      this.loading = true;
      this.$store
        .dispatch(this.update_action, {
          ...this.edited_item,
        })
        .then(() => {
          this.getResults();
        })
        .catch((err) => {
          this.$store.dispatch("alert/show", {
            message: `Feil i ${this.update_action}: ${err.message}`,
            type: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    createItem() {
      this.loading = true;
      this.$store
        .dispatch(this.create_action, {
          parent_id: this.parent_id,
          ...this.edited_item,
        })
        .then(() => {
          this.getResults();
          if (this.get_department_id) {
            // temp hack
            window.location.reload();
          }
        })
        .catch((err) => {
          this.$store.dispatch("alert/show", {
            message: `Feil i ${this.create_action}: ${err.message}`,
            type: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deleteItem(item) {
      confirm("Er du sikker på at du ønsker å slette denne raden?") &&
        this.$store
          .dispatch(this.delete_action, {
            ...item,
          })
          .then(() => {
            this.getResults();
          })
          .catch((err) => {
            this.$store.dispatch("alert/show", {
              message: `Feil i ${this.delete_action}: ${err.message}`,
              type: "error",
            });
          })
          .finally(() => {
            this.loading = false;
          });
    },
    routeTo($event) {
      if (this.router_link) {
        this.$router.push({
          name: "edit-customer",
          params: { id: $event.id },
        });
      }
    },
    getServiceGroupsById(department_id) {
      this.$store.dispatch("tsip/get_service_groups", {
        parent_id: department_id,
      });
    },
    getRowClasses(item) {
      if (
        item.end_date &&
        moment(item.end_date) < moment().subtract(1, "month")
      ) {
        return "text--disabled";
      }
      if (item.frozen_date && moment(item.frozen_date) < moment()) {
        return "light-blue";
      }
      if (
        item.departments &&
        item.departments.every((v) => v && moment(v.end_date) < moment())
      ) {
        return "text--disabled";
      }
    },
  },
  computed: {
    formTitle() {
      return this.editing
        ? "Endre " + this.singular
        : "Opprett " + this.singular;
    },
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
  },
  filters: {
    toDate: (date) => moment(date).format("YYYY-MM-DD"),
  },
  watch: {
    options: {
      handler() {
        this.getResults();
      },
      deep: true,
    },
    dialog(val) {
      val || this.close();
    },
    // Vuetify doesn't support async rules yet, so we have to do it here instead of in the validation file
    edited_item: {
      handler(val) {
        if (val.number && val.number.length === 8) {
          this.$store
            .dispatch("tsip/get_number_status", { number: val.number })
            .then((res) => {
              if (!res.data.available && !res.data.in_use) {
                this.errors = ["Nummeret eksisterer ikke i våre nummerserier"];
              }
              if (res.data.available && res.data.in_use && !this.editing) {
                this.errors = ["Nummeret er alerede i bruk"];
              }
            });
          this.errors = [];
        } else {
          this.errors = [];
        }
      },
      deep: true,
    },
  },
};
</script>
<style>
.tf-pointer tr {
  cursor: pointer;
}
.mobile-stripes tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
  display: block !important;
}
.v-slide-group__prev {
  display: none !important;
}
</style>
