import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import user from "./modules/user";
import number from "./modules/number";
import destination from "./modules/destination";
import origin from "./modules/origin";
import customer from "./modules/customer";
import department from "./modules/department";
import subscription from "./modules/subscription";
import order from "./modules/order";
import provider from "./modules/provider";
import integration from "./modules/integration";
import product from "./modules/product";
import tsip from "./modules/tsip";
import number_group from "./modules/number_group";
import campaign from "./modules/campaign";
import payment_type from "./modules/payment_type";
import message from "./modules/message";
import supported_payment_option from "./modules/supported_payment_option";
import charge_strategy from "./modules/charge_strategy"
import charge_moment from "./modules/charge_moment";
import incomplete_call_strategy from "./modules/incomplete_call_strategy";
import alert from "./modules/alert";

import settings from "./modules/settings";
Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {
    auth,
    user,
    number,
    destination,
    origin,
    customer,
    department,
    subscription,
    order,
    provider,
    integration,
    product,
    tsip,
    number_group,
    campaign,
    payment_type,
    message,
    supported_payment_option,
    charge_strategy,
    charge_moment,
    incomplete_call_strategy,
    alert,
    settings
  },
  strict: true
});
