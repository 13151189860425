<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="periodInput"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="periodInput"
        label="Filtrer på periode"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
        hide-details
        :clearable="clearable"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="periodInput"
      no-title
      scrollable
      range
      :max="maxdate"
    >
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="menu = false">
        Cancel
      </v-btn>
      <v-btn text color="primary" @click="$refs.menu.save(periodInput)">
        OK
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: {
    period: Array,
    maxdate: String,
    clearable: Boolean
  },
  data() {
    return {
      menu: false,
      modal: false,
      menu2: false
    };
  },
  computed: {
    periodInput: {
      get: function() {
        return this.period;
      },
      set: function(period) {
        this.$emit("update:period", period);
      }
    }
  }
};
</script>
