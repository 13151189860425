var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{class:{
    'tf-pointer': _vm.router_link,
    'mobile-stripes': _vm.$vuetify.breakpoint.xs,
  },attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.total,"show-select":_vm.show_select && _vm.total > 1,"single-select":true,"item-class":_vm.getRowClasses},on:{"update:options":function($event){_vm.options=$event},"input":_vm.get_department_id,"click:row":_vm.routeTo},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[(_vm.total > 10 || _vm.options.query !== '')?_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Søk","single-line":"","hide-details":""},model:{value:(_vm.options.query),callback:function ($$v) {_vm.$set(_vm.options, "query", $$v)},expression:"options.query"}}):_vm._e(),_c('v-spacer'),(_vm.show_date_filters)?_c('date-picker',{attrs:{"period":_vm.options.period,"clearable":""},on:{"update:period":function($event){return _vm.$set(_vm.options, "period", $event)}}}):_vm._e(),_c('v-spacer'),(_vm.show_date_filters)?_c('v-checkbox',{attrs:{"label":"Kun annonser","hide-details":""},model:{value:(_vm.options.is_advertisement),callback:function ($$v) {_vm.$set(_vm.options, "is_advertisement", $$v)},expression:"options.is_advertisement"}}):_vm._e(),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([(_vm.create_action)?{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.onCreate()}}},on),[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.singular)+" ")],1)]}}:null],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-form',{model:{value:(_vm.is_valid),callback:function ($$v) {_vm.is_valid=$$v},expression:"is_valid"}},[_c('data-table-headers',{attrs:{"headers":_vm.headers,"edited_item":_vm.edited_item,"change_password":_vm.change_password,"show_create_user":_vm.show_create_user,"editing":_vm.editing,"create_info":_vm.create_info,"show_products_select":_vm.show_products_select},on:{"update:edited_item":function($event){_vm.edited_item=$event}}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.close}},[_vm._v("Avbryt")]),_c('v-btn',{attrs:{"color":"primary","text":"","disabled":!_vm.is_valid},on:{"click":_vm.save}},[_vm._v("Lagre")])],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
  var item = ref.item;
return [(_vm.update_action)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]):_vm._e(),(_vm.delete_action)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")]):_vm._e()]}},{key:"item.calc_vat",fn:function(ref){
  var item = ref.item;
return [(item.calc_vat)?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-check")]):_vm._e()]}},{key:"item.is_incoming_traffic",fn:function(ref){
  var item = ref.item;
return [(item.is_incoming_traffic)?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-check")]):_vm._e()]}},{key:"item.is_outgoing_traffic",fn:function(ref){
  var item = ref.item;
return [(item.is_outgoing_traffic)?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-check")]):_vm._e()]}},{key:"item.calc_provision_vat",fn:function(ref){
  var item = ref.item;
return [(item.calc_provision_vat)?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-check")]):_vm._e()]}},{key:"item.is_prepaid",fn:function(ref){
  var item = ref.item;
return [(item.is_prepaid)?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-check")]):_vm._e()]}},{key:"item.is_advertisement",fn:function(ref){
  var item = ref.item;
return [(item.is_advertisement)?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-check")]):_vm._e()]}},{key:"item.quarterly_billing",fn:function(ref){
  var item = ref.item;
return [(item.quarterly_billing)?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-check")]):_vm._e()]}},{key:"item.department.name",fn:function(ref){
  var item = ref.item;
return [_c('router-link',{attrs:{"to":{
        name: 'edit-customer',
        params: { id: item.department.customer_id },
      }}},[_vm._v(" "+_vm._s(item.department.name)+" ")])]}},{key:"item.product_id",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.product && item.product.description ? item.product.description : "")+" ")]}},{key:"item.created_at",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toDate")(item.created_at))+" ")]}},{key:"item.roles",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.roles.map(function (e) { return e.name; }).join(", "))+" ")]}},{key:"item.messages",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.messages.map(function (e) { return e.description; }).join(", "))+" ")]}},{key:"item.payment_types",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.payment_types.map(function (e) { return e.description; }).join(", "))+" ")]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})}
var staticRenderFns = []

export { render, staticRenderFns }