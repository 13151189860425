<template>
  <v-data-table
    :headers="headers"
    :items="report"
    :loading="loading"
    :options="options"
    :item-class="getRowClasses"
  >
    <template v-slot:top>
      <v-row justify="space-between">
        <v-col>
          <v-toolbar flat>
            <date-picker
              :period.sync="period"
              :maxdate="getMaxDate()"
              :clearable="false"
            ></date-picker>
            <v-spacer></v-spacer>

            <v-select
              v-if="is_super_admin"
              item-text="company_name"
              item-value="id"
              v-model="selected_provider_id"
              :items="getProviders"
              label="Filtrer på Provider"
              clearable
              hide-details
            >
            </v-select>
            <v-spacer v-if="is_super_admin"></v-spacer>

            <v-btn
              class="mb-5"
              color="primary"
              :disabled="period && (!period[0] || !period[1])"
              @click="getCdrReport()"
            >
              <v-icon>mdi-reload</v-icon>
              Hent data</v-btn
            >
            <v-btn
              v-if="report.length > 0"
              class="mb-5 ml-5"
              color="primary"
              @click="downloadReport()"
            >
              <v-icon>mdi-download</v-icon>

              Last ned</v-btn
            >
          </v-toolbar>
        </v-col>
      </v-row>
    </template>
    <template v-slot:item.amount="{ item }">{{
      item.amount | toCurrency
    }}</template>
  </v-data-table>
</template>
<script>
import XLSX from "xlsx";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  props: {
    is_super_admin: Boolean
  },
  data: () => ({
    loading: false,
    period: [],
    selected_provider_id: null,
    report: [],
    options: {
      itemsPerPage: -1
    },
    headers: [
      {
        text: "Beskrivelse",
        align: "start",
        sortable: false,
        value: "description"
      },
      { text: "Antall", value: "count" },
      { text: "Minutter", value: "minutes" },
      { text: "Beløp", value: "amount" }
    ]
  }),
  name: "tsip-report",
  methods: {
    getRowClasses(item) {
      if (item.description == "Totalt fra Telia") {
        return "tf-line-top";
      }
      if (
        item.description == "Totalt" ||
        item.description == "Inntekter totalt"
      ) {
        return "tf-line";
      }
    },
    getMaxDate() {
      if (this.period) {
        return moment(this.period[0])
          .add(1, "M")
          .format("YYYY-MM-DD");
      }
    },
    getCdrReport() {
      this.loading = true;
      this.$store
        .dispatch("tsip/get_reports", {
          period: this.period,
          provider_id: this.selected_provider_id
        })
        .then(res => {
          this.loading = false;
          this.report = res;
        })
        .catch(err => {
          this.$store.dispatch("alert/show", {
            message: `Feil i tsip/get_reports: ${err.message}`,
            type: "error"
          });
        });
    },
    downloadReport() {
      const header = ["description", "count", "minutes", "amount"];
      const data = XLSX.utils.json_to_sheet(this.report, { header: header });
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, "report.xlsx");
    }
  },
  filters: {
    toCurrency: function(val) {
      if (typeof val !== "number") {
        return val;
      }
      var formatter = new Intl.NumberFormat("nb-NO", {
        style: "currency",
        currency: "NOK",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      });
      return formatter.format(val);
    },
    toMinutes: function(val) {
      if (typeof val !== "number") {
        return val;
      }
      let minutes = val / 60;
      return minutes.toFixed(0);
    }
  },
  computed: {
    ...mapGetters("provider", ["getProviders"])
  },
  mounted() {
    if (this.is_super_admin) {
      this.$store.dispatch("provider/get").catch(err => {
        this.$store.dispatch("alert/show", {
          message: `Feil i provider/get: ${err.message}`,
          type: "error"
        });
      });
    }
  }
};
</script>
<style>
.tf-line-top td {
  border-top: 1px solid;
}
.tf-line td {
  border-top: 1px solid !important;
  border-bottom: 1px solid !important;
}
</style>
