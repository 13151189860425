import axios from "axios";

const API_SERVER = process.env.VUE_APP_API_SERVER;

export const api_routes = {
  user: {
    login: API_SERVER + "auth/login",
    //signup: API_SERVER + "register",
    me: API_SERVER + "auth/getuser",
    forgotpassword: API_SERVER + "auth/reset-password",
    passwordreset: API_SERVER + "auth/reset/password",
    get: API_SERVER + "auth/users",
    update: API_SERVER + "auth/users",
    create: API_SERVER + "auth/users",
    delete: API_SERVER + "auth/users",
    roles: API_SERVER + "auth/roles"
  },
  number: {
    get: API_SERVER + "numbers",
    get_by_department: API_SERVER + "numbers",
    update: API_SERVER + "numbers",
    create: API_SERVER + "numbers",
    delete: API_SERVER + "numbers"
  },
  customer: {
    get: API_SERVER + "customers",
    get_all: API_SERVER + "customers/all",
    update: API_SERVER + "customers",
    create: API_SERVER + "customers",
    delete: API_SERVER + "customers"
  },
  destination: {
    get: API_SERVER + "destinations",
    update: API_SERVER + "destinations",
    create: API_SERVER + "destinations",
    delete: API_SERVER + "destinations"
  },
  origin: {
    get: API_SERVER + "origins",
    update: API_SERVER + "origins",
    create: API_SERVER + "origins",
    delete: API_SERVER + "origins"
  },
  department: {
    get: API_SERVER + "departments",
    get_by_customer: API_SERVER + "departments",
    get_tripletex_categories: API_SERVER + "departments/categories",
    update: API_SERVER + "departments",
    create: API_SERVER + "departments",
    delete: API_SERVER + "departments"
  },
  subscription: {
    get: API_SERVER + "subscriptions",
    get_by_department: API_SERVER + "subscriptions",
    update: API_SERVER + "subscriptions",
    create: API_SERVER + "subscriptions",
    delete: API_SERVER + "subscriptions"
  },
  order: {
    get: API_SERVER + "orders"
  },
  provider: {
    get: API_SERVER + "providers",
    update: API_SERVER + "providers",
    create: API_SERVER + "providers",
    delete: API_SERVER + "providers"
  },
  integration: {
    get: API_SERVER + "integrations",
    update: API_SERVER + "integrations",
    create: API_SERVER + "integrations",
    delete: API_SERVER + "integrations"
  },
  product: {
    get: API_SERVER + "products",
    get_all: API_SERVER + "products/all",
    update: API_SERVER + "products",
    create: API_SERVER + "products",
    delete: API_SERVER + "products"
  },
  tsip: {
    get_service_groups: API_SERVER + "tsip/service_groups",
    get_service_categories: API_SERVER + "tsip/service_categories",
    get_number_status: API_SERVER + "tsip/number_status",
    get_all_numbers: API_SERVER + "tsip/numbers",
    get_reports: API_SERVER + "tsip/reports"
  },
  number_group: {
    get: API_SERVER + "number_groups",
    update: API_SERVER + "number_groups",
    create: API_SERVER + "number_groups",
    delete: API_SERVER + "number_groups"
  },
  payment_type: {
    get: API_SERVER + "payment_types",
    update: API_SERVER + "payment_types",
    create: API_SERVER + "payment_types",
    delete: API_SERVER + "payment_types"
  },
  message: {
    get: API_SERVER + "messages",
    update: API_SERVER + "messages",
    create: API_SERVER + "messages",
    delete: API_SERVER + "messages"
  },
  campaign: {
    get: API_SERVER + "campaigns",
    update: API_SERVER + "campaigns",
    create: API_SERVER + "campaigns",
    delete: API_SERVER + "campaigns"
  },
  supported_payment_option: {
    get: API_SERVER + "supported_payment_options",
    update: API_SERVER + "supported_payment_options",
    create: API_SERVER + "supported_payment_options",
    delete: API_SERVER + "supported_payment_options"
  },
  charge_strategy: {
    get: API_SERVER + "charge_strategies",
    update: API_SERVER + "charge_strategies",
    create: API_SERVER + "charge_strategies",
    delete: API_SERVER + "charge_strategies"
  },
  charge_moment: {
    get: API_SERVER + "charge_moments",
    update: API_SERVER + "charge_moments",
    create: API_SERVER + "charge_moments",
    delete: API_SERVER + "charge_moments"
  },
  incomplete_call_strategy: {
    get: API_SERVER + "incomplete_call_strategies",
    update: API_SERVER + "incomplete_call_strategies",
    create: API_SERVER + "incomplete_call_strategies",
    delete: API_SERVER + "incomplete_call_strategies"
  }

};

export const apiCall = ({ url, method, ...args }) =>
  new Promise((resolve, reject) => {
    let token = localStorage.getItem("user-token") || "";

    if (token)
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    try {
      axios({
        method: method || "get",
        url: url,
        ...args
      })
        .then(resp => {
          resolve(resp.data);
        })
        .catch(error => {
          reject(error);
        });
    } catch (err) {
      reject(new Error(err));
    }
  });
