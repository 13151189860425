import { apiCall, api_routes } from "@/utils/api";

const state = {
  status: "",
  service_categories: [],
  service_groups: [],
  number_status: [],
  all_numbers: [],
  reports: [],
  error: ""
};

const getters = {
  getServiceGroups: state => state.service_groups,
  getServiceCategories: state => state.service_categories,
  getNumberStatus: state => state.number_status,
  getAllNumbers: state => state.all_numbers,
  getReports: state => state.reports
};

const actions = {
  get_service_groups({ commit }, request) {
    return new Promise((resolve, reject) => {
      apiCall({
        url: api_routes.tsip.get_service_groups + "/" + request.parent_id,
        method: "get"
      })
        .then(resp => {
          commit("get_service_groups_success", resp);
          resolve(resp);
        })
        .catch(err => {
          commit("admin_error", err);
          reject(err);
        });
    });
  },
  get_number_status({ commit }, request) {
    return new Promise((resolve, reject) => {
      apiCall({
        url: api_routes.tsip.get_number_status + "/" + request.number,
        method: "get"
      })
        .then(resp => {
          commit("get_number_status_success", resp);
          resolve(resp);
        })
        .catch(err => {
          commit("admin_error", err);
          reject(err);
        });
    });
  },
  get_all_numbers({ commit }, request) {
    return new Promise((resolve, reject) => {
      apiCall({
        url:
          api_routes.tsip.get_all_numbers +
          "?page=" +
          request.page +
          "&query=" +
          request.query +
          "&sortBy=" +
          request.sortBy +
          "&sortDesc=" +
          request.sortDesc +
          "&itemsPerPage=" +
          request.itemsPerPage,
        method: "get"
      })
        .then(resp => {
          commit("get_all_numbers_success", resp.data);
          resolve(resp.data);
        })
        .catch(err => {
          commit("admin_error", err);
          reject(err);
        });
    });
  },
  get_service_categories({ commit }) {
    return new Promise((resolve, reject) => {
      apiCall({
        url: api_routes.tsip.get_service_categories,
        method: "get"
      })
        .then(resp => {
          commit("get_all_service_categories_success", resp);
          resolve(resp);
        })
        .catch(err => {
          commit("admin_error", err);
          reject(err);
        });
    });
  },
  get_reports({ commit }, request) {
    return new Promise((resolve, reject) => {
      apiCall({
        url: api_routes.tsip.get_reports + "/" + request.provider_id,
        data: request,
        method: "post"
      })
        .then(resp => {
          commit("get_reports_success", resp);
          resolve(resp);
        })
        .catch(err => {
          commit("admin_error", err);
          reject(err);
        });
    });
  }
};
const mutations = {
  get_service_groups_success(state, resp) {
    state.service_groups = resp.data;
  },
  get_number_status_success(state, resp) {
    state.number_status = resp.data;
  },
  get_all_numbers_success(state, resp) {
    state.all_numbers = resp.data;
  },
  get_all_service_categories_success(state, resp) {
    state.service_categories = resp.data;
  },
  get_reports_success(state, resp) {
    state.reports = resp.data;
  },
  admin_success(state, resp) {
    state.status = resp;
  },
  admin_error(state, err) {
    state.error = err;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
