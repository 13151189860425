<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card class="elevation-1 mt-4">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title>{{ title }}</v-toolbar-title>
          </v-toolbar>
          <data-table
            :headers="getDepartmentHeaders()"
            :get_action="`department/get_by_customer`"
            :update_action="`department/update`"
            :create_action="`department/create`"
            :delete_action="`department/delete`"
            :parent_id="Number(this.$route.params.id)"
            :get_department_id="getDepartmentId"
            :show_select="true"
            title="Velg avdeling"
            :singular="`avdeling`"
            :create_info="
              'Avdelingen vil også opprettes i TSIP og bli tildelt en service-gruppe'
            "
          ></data-table>
          <v-tabs v-if="department_id != null" v-model="tab">
            <v-tab>Månedlige trekk</v-tab>
            <v-tab>Nummer</v-tab>
            <v-tab>Ordrearkiv</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" touchless>
            <v-tab-item :eager="true">
              <data-table
                :headers="getSubscriptionHeaders()"
                :get_action="`subscription/get_by_department`"
                :update_action="`subscription/update`"
                :create_action="`subscription/create`"
                :delete_action="`subscription/delete`"
                parent_name="Produkt"
                :parent_id="this.department_id"
                ref="subscriptions"
                :singular="`månedlig trekk`"
                :show_products_select="true"
              ></data-table>
            </v-tab-item>
            <v-tab-item :eager="true">
              <data-table
                :headers="getNumberHeaders()"
                :get_action="`number/get`"
                :update_action="`number/update`"
                :create_action="`number/create`"
                :delete_action="`number/delete`"
                :parent_id="this.department_id"
                :show_service_groups_select="true"
                parent_name="Servicegruppe i TSIP"
                ref="numbers"
                :singular="`Nummer`"
                :create_info="
                  'Nummeret vil også opprettes i TSIP dersom denne kunden finnes'
                "
              ></data-table>
            </v-tab-item>
            <v-tab-item>
              <orders-table
                :items="orders"
                :headers="getOrderHeaders()"
              ></orders-table>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import number_group from "../../../store/modules/number_group";

export default {
  data: () => ({
    title: "",
    tab: null,
    department_id: null,
    orders: [],
  }),
  name: "admin",
  methods: {
    getNumberHeaders() {
      return [
        {
          text: "ID",
          align: "start",
          sortable: true,
          value: "id",
          updatable: false,
        },
        {
          text: "Opprettet",
          sortable: true,
          value: "created_at",
          updatable: false,
        },
        {
          text: "ServiceGruppe i TSIP",
          value: "servicegroup_id",
          sortable: false,
          updatable: true,
          type: "select",
          item_text: "Description",
          item_value: "SG_ID",
          items: this.getServiceGroups,
          parent_id: "servicegroup_id",
          align: " d-none",
        },
        {
          text: "Kategori i TSIP",
          value: "servicecategory_id",
          sortable: false,
          updatable: true,
          type: "select",
          item_text: "ServiceCategoryName",
          item_value: "SC_ID",
          items: this.getServiceCategories,
          parent_id: "servicecategory_id",
          align: " d-none",
        },
        {
          text: "Nummer",
          value: "number",
          updatable: true,
          required: true,
          type: "phone",
        },
        {
          text: "Beskrivelse",
          value: "description",
          updatable: true,
          required: true,
          type: "string",
        },
        {
          text: "Provisjon/min",
          value: "provision_per_minute",
          updatable: true,
          required: true,
          type: "amount",
        },
        {
          text: "Provisjon/samtale",
          value: "provision_per_call",
          updatable: true,
          required: true,
          type: "amount",
        },
        {
          text: "Pris/min mobil",
          value: "price_per_minute_mobile",
          updatable: true,
          required: true,
          type: "amount",
        },
        {
          text: "Pris/samtale mobil",
          value: "price_per_call_mobile",
          updatable: true,
          required: true,
          type: "amount",
        },
        {
          text: "Avgift/min",
          value: "cost_per_minute",
          updatable: true,
          required: true,
          type: "amount",
        },
        {
          text: "Avgift/samtale",
          value: "cost_per_call",
          updatable: true,
          required: true,
          type: "amount",
        },
        {
          text: "NummerGruppe",
          value: "number_group.description",
          sortable: false,
          updatable: true,
          type: "select",
          item_text: "description",
          item_value: "id",
          items: this.getNumberGroups,
          parent: "number_group",
          parent_id: "number_group_id",
        },
        {
          text: "Provider",
          value: "provider.company_name",
          updatable: true,
          required: false,
          type: "select",
          item_text: "company_name",
          item_value: "id",
          parent: "provider",
          parent_id: "provider_id",
          items: this.getProviders,
        },
        {
          text: "Meldinger",
          value: "messages",
          updatable: true,
          required: false,
          sortable: false,
          type: "multiselect",
          item_text: "description",
          item_value: "id",
          items: this.getMessages,
        },
        {
          text: "Start-dato",
          value: "start_date",
          updatable: true,
          required: true,
          type: "date",
        },
        {
          text: "Slutt-dato",
          value: "end_date",
          updatable: true,
          required: false,
          clearable: true,
          type: "date",
        },
        {
          text: "Fryst-dato",
          value: "frozen_date",
          updatable: true,
          required: false,
          clearable: true,
          type: "date",
        },
        {
          text: "Handling",
          value: "actions",
          sortable: false,
          updatable: false,
          align: "end",
        },
      ];
    },
    getDepartmentHeaders() {
      return [
        {
          text: "ID",
          align: "start",
          sortable: true,
          value: "id",
          updatable: false,
        },
        {
          text: "Opprettet",
          sortable: true,
          value: "created_at",
        },
        {
          text: "Avdelingsnavn",
          value: "name",
          updatable: true,
          required: true,
          type: "string",
        },
        {
          text: "Faktura Epost",
          value: "invoice_email",
          updatable: true,
          required: false,
          type: "email",
        },
        {
          text: "Kategori i Tripletex",
          value: "tripletex_category_id",
          updatable: true,
          required: false,
          type: "select",
          item_text: "name",
          item_value: "id",
          parent_id: "tripletex_category_id",
          items: this.getTripletexCategories,
          align: " d-none",
        },
        {
          text: "Addresse",
          value: "billing_address",
          updatable: true,
          required: true,
          type: "textfield",
        },
        {
          text: "Postnummer",
          value: "zip_code",
          updatable: true,
          required: true,
          type: "string",
        },
        {
          text: "By",
          value: "city",
          updatable: true,
          required: true,
          type: "string",
        },
        {
          text: "Land",
          value: "country",
          updatable: true,
          required: true,
          type: "string",
        },
        {
          text: "Start-dato",
          value: "start_date",
          updatable: true,
          required: true,
          type: "date",
        },
        {
          text: "Slutt-dato",
          value: "end_date",
          updatable: true,
          required: false,
          clearable: true,
          type: "date",
        },
        {
          text: "Kontakt",
          value: "contact_person",
          updatable: true,
          required: false,
          type: "string",
          align: " d-none",
        },
        {
          text: "Telefon",
          value: "contact_number",
          updatable: true,
          required: false,
          type: "string",
          align: " d-none",
        },
        {
          text: "Handling",
          value: "actions",
          sortable: false,
          updatable: false,
          align: "end",
        },
      ];
    },
    getOrderHeaders() {
      return [
      {
          text: "ID",
          align: "start",
          sortable: true,
          value: "id",
          updatable: false,
        },
        {
          text: "Opprettet",
          align: "start",
          sortable: true,
          value: "created_at",
        },
        {
          text: "Ordredato",
          value: "order_date",
        },
        {
          text: "Beskrivelse",
          value: "description",
        },
        {
          text: "Totalbeløp",
          value: "amount",
          align: "end",
        },
      ];
    },
    getSubscriptionHeaders() {
      return [
      {
          text: "ID",
          align: "start",
          sortable: true,
          value: "id",
          updatable: false,
        },
        {
          text: "Opprettet",
          align: "start",
          sortable: true,
          value: "created_at",
        },
        {
          text: "Produkt",
          value: "product_id",
          sortable: true,
          updatable: true,
          type: "select",
          item_text: "description",
          item_value: "id",
          items: this.getAllProducts,
          parent_id: "product_id",
          clearable: true,
        },
        {
          text: "Forskuddsbetalt",
          value: "is_prepaid",
          updatable: true,
          align: "center",
          type: "boolean",
        },
        {
          text: "Annonse",
          value: "is_advertisement",
          updatable: true,
          align: "center",
          type: "boolean",
        },
        {
          text: "Beskrivelse",
          value: "description",
          updatable: true,
          required: true,
          type: "string",
        },
        {
          text: "Antall",
          value: "count",
          updatable: true,
          required: true,
          type: "string",
        },
        {
          text: "Start-dato",
          value: "start_date",
          updatable: true,
          required: true,
          type: "date",
        },
        {
          text: "Slutt-dato",
          value: "end_date",
          updatable: true,
          required: false,
          clearable: true,
          type: "date",
        },
        {
          text: "Beløp per stk",
          value: "amount",
          updatable: true,
          required: true,
          type: "amount",
        },
        {
          text: "Handling",
          value: "actions",
          sortable: false,
          updatable: false,
          align: "end",
        },
      ];
    },
    getDepartmentId($event) {
      if ($event && $event[0]) {
        this.title = $event[0].name;
        this.department_id = $event[0].id;
        this.$refs.subscriptions.getResults();
        this.$refs.numbers.getResults();
        this.getOrders();
      } else {
        this.title = "Legg til en avdeling";
      }
    },
    getOrders() {
      this.$store
        .dispatch("order/get", {
          parent_id: this.department_id,
          page: "",
          query: "",
          itemsPerPage: -1,
          sortBy: "",
          sortDesc: "",
        })
        .then((res) => {
          this.orders = res.data;
        })
        .catch((err) => {
          this.$store.dispatch("alert/show", {
            message: `Feil i order/get: ${err.message}`,
            type: "error",
          });
        });
    },
  },
  computed: {
    ...mapGetters("user", ["getProfile"]),
    ...mapGetters("provider", ["getProviders"]),
    ...mapGetters("tsip", ["getServiceCategories", "getServiceGroups"]),
    ...mapGetters("department", ["getTripletexCategories"]),
    ...mapGetters("product", ["getAllProducts"]),
    ...mapGetters("number_group", ["getNumberGroups"]),
    ...mapGetters("campaign", ["getCampaigns"]),
  },
  mounted() {
    this.getOrders();
    this.$store.dispatch("number_group/get", {
      page: "",
      query: "",
      itemsPerPage: -1,
      sortBy: "",
      sortDesc: "",
    });
    this.$store.dispatch("provider/get");
    this.$store.dispatch("tsip/get_service_categories");
    this.$store.dispatch("department/get_tripletex_categories");
    this.$store.dispatch("product/get_all");
  },
};
</script>
<style scoped></style>
