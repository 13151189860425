<template>
  <v-container fill-height>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-6">
          <v-toolbar color="primary" flat dark>
            <v-toolbar-title>Nytt passord</v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-card-text>
            <v-alert outlined type="info" color="primary">
              Du vil motta en epost med lenke for å sette nytt passord.
            </v-alert>
            <v-form v-model="isValid">
              <v-text-field
                v-model="email"
                label="Epost"
                name="epost"
                prepend-icon="person"
                type="email"
                :rules="[requiredRule('Epost', true), emailRule()]"
              />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              :disabled="!isValid"
              @click="requestResetPassword()"
              >Send</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { settings } from "@/settings";
import validationRules from "@/validation/rules.js";

export default {
  data() {
    return {
      email: null,
      isValid: true
    };
  },
  methods: {
    requestResetPassword() {
      const { email } = this;
      this.$store
        .dispatch("auth/forgot_password", {
          email
        })
        .then(res => {
          this.$store.dispatch("alert/show", {
            message: res.message,
            type: "info"
          });
        })
        .catch(err => {
          this.$store.dispatch("alert/show", {
            message: `Feil under sending av epost: ${err.message}`,
            type: "error"
          });
        });
    },
    ...validationRules
  }
};
</script>
