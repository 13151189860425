import { apiCall, api_routes } from "@/utils/api";

const state = {
  status: "",
  number_groups: [],
  error: ""
};

const getters = {
  getNumberGroups: state => state.number_groups
};

const actions = {
  get({ commit }, request) {
    if (!request) {
      request = {
        page: "",
        query: "",
        itemsPerPage: -1,
        sortBy: "",
        sortDesc: ""
      };
    }
    return new Promise((resolve, reject) => {
      apiCall({
        url:
          api_routes.number_group.get +
          "?page=" +
          request.page +
          "&query=" +
          request.query +
          "&sortBy=" +
          request.sortBy +
          "&sortDesc=" +
          request.sortDesc +
          "&itemsPerPage=" +
          request.itemsPerPage +
          "&provider_id=" +
          request.provider_id,
        method: "get"
      })
        .then(resp => {
          commit("get_number_groups_success", resp.data);
          resolve(resp.data);
        })
        .catch(err => {
          commit("admin_error", err);
          reject(err);
        });
    });
  },

  update({ commit }, request) {
    return new Promise((resolve, reject) => {
      apiCall({
        url: api_routes.number_group.update + "/" + request.id,
        data: request,
        method: "put"
      })
        .then(resp => {
          commit("admin_success", resp);
          resolve(resp);
        })
        .catch(err => {
          commit("admin_error", err);
          reject(err);
        });
    });
  },

  create({ commit }, request) {
    return new Promise((resolve, reject) => {
      apiCall({
        url: api_routes.number_group.create,
        data: request,
        method: "post"
      })
        .then(resp => {
          commit("admin_success", resp);
          resolve(resp);
        })
        .catch(err => {
          commit("admin_error", err);
          reject(err);
        });
    });
  },
  delete({ commit }, request) {
    return new Promise((resolve, reject) => {
      apiCall({
        url: api_routes.number_group.delete + "/" + request.id,
        data: request,
        method: "delete"
      })
        .then(resp => {
          commit("admin_success", resp);
          resolve(resp);
        })
        .catch(err => {
          commit("admin_error", err);
          reject(err);
        });
    });
  }
};
const mutations = {
  get_number_groups_success(state, resp) {
    state.number_groups = resp.data;
  },
  admin_success(state, resp) {
    state.status = resp;
  },
  admin_error(state, err) {
    state.error = err;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
