<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card class="elevation-1 mt-4">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title>SuperAdmin</v-toolbar-title>
          </v-toolbar>
          <v-tabs v-model="tab">
            <v-tab>Brukere</v-tab>
            <v-tab>Providere</v-tab>
            <v-tab>Integrasjoner</v-tab>
            <v-tab>Støttede Betalingsløsninger</v-tab>
            <v-tab>Charge Strategier</v-tab>
            <v-tab>Charge Tidspunkter</v-tab>
            <v-tab>Uferdig samtale Strategier</v-tab>
            <v-tab>Opprinnelser</v-tab>
            <v-tab>Full TSIP Rapport</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item :eager="true">
              <data-table
                :headers="getUserHeaders()"
                :get_action="`user/get`"
                :update_action="`user/update`"
                :create_action="`user/create`"
                :delete_action="`user/delete`"
                :singular="`bruker`"
                :change_password="true"
              ></data-table>
            </v-tab-item>
            <v-tab-item :eager="true">
              <data-table
                :headers="getProviderHeaders()"
                :get_action="`provider/get`"
                :update_action="`provider/update`"
                :create_action="`provider/create`"
                :delete_action="`provider/delete`"
                :singular="`provider`"
              ></data-table>
            </v-tab-item>
            <v-tab-item :eager="true">
              <data-table
                :headers="getIntegrationHeaders()"
                :get_action="`integration/get`"
                :update_action="`integration/update`"
                :create_action="`integration/create`"
                :delete_action="`integration/delete`"
                :singular="`integrasjon`"
              ></data-table>
            </v-tab-item>
            <v-tab-item :eager="true">
              <data-table
                :headers="getSupportedPaymentOptionHeaders()"
                :get_action="`supported_payment_option/get`"
                :update_action="`supported_payment_option/update`"
                :create_action="`supported_payment_option/create`"
                :delete_action="`supported_payment_option/delete`"
                :singular="`støttet betalingsløsning`"
              ></data-table>
            </v-tab-item>
            <v-tab-item :eager="true">
              <data-table
                :headers="getChargeStrategiesHeaders()"
                :get_action="`charge_strategy/get`"
                :update_action="`charge_strategy/update`"
                :create_action="`charge_strategy/create`"
                :delete_action="`charge_strategy/delete`"
                :singular="`charge strategi`"
              ></data-table>
            </v-tab-item>
            <v-tab-item :eager="true">
              <data-table
                :headers="getChargeMomentsHeaders()"
                :get_action="`charge_moment/get`"
                :update_action="`charge_moment/update`"
                :create_action="`charge_moment/create`"
                :delete_action="`charge_moment/delete`"
                :singular="`charge tidspunkt`"
              ></data-table>
            </v-tab-item>
            <v-tab-item :eager="true">
              <data-table
                :headers="getIncompleteCallStrategiesHeaders()"
                :get_action="`incomplete_call_strategy/get`"
                :update_action="`incomplete_call_strategy/update`"
                :create_action="`incomplete_call_strategy/create`"
                :delete_action="`incomplete_call_strategy/delete`"
                :singular="`uferdig samtale strategi`"
              ></data-table>
            </v-tab-item>
            <v-tab-item :eager="true">
              <data-table
                :headers="getOriginHeaders()"
                :get_action="`origin/get`"
                :update_action="`origin/update`"
                :create_action="`origin/create`"
                :delete_action="`origin/delete`"
                :singular="`opprinnelse`"
              ></data-table>
            </v-tab-item>
            <v-tab-item :eager="true">
              <tsip-report :is_super_admin="isSuperAdmin"></tsip-report>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "superadmin",
  data() {
    return {
      tab: null,
      group: null,
    };
  },
  methods: {
    getSupportedPaymentOptionHeaders() {
      return [
        {
          text: "ID",
          align: "start",
          sortable: true,
          value: "id",
          updatable: false,
        },
        {
          text: "Opprettet",
          align: "start",
          sortable: true,
          value: "created_at",
        },
        {
          text: "Beskrivelse",
          value: "description",
          updatable: true,
          required: true,
          type: "string",
        },
        {
          text: "Meldinger",
          value: "messages",
          updatable: true,
          required: false,
          sortable: false,
          type: "multiselect",
          item_text: "description",
          item_value: "id",
          items: this.getMessages,
        },
        { text: "Handling", value: "actions", sortable: false, align: "end" },
      ];
    },
    getChargeStrategiesHeaders() {
      return [
      {
          text: "ID",
          align: "start",
          sortable: true,
          value: "id",
          updatable: false,
        },
        {
          text: "Opprettet",
          align: "start",
          sortable: true,
          value: "created_at",
        },
        {
          text: "Beskrivelse",
          value: "description",
          updatable: true,
          required: true,
          type: "string",
        },
        {
          text: "Minutter /periode",
          value: "initial_period_duration",
          updatable: true,
          required: true,
          type: "string",
        },
        {
          text: "Perioder før sekundær",
          value: "initial_period_count",
          updatable: true,
          required: true,
          type: "string",
        },
        {
          text: "Minutter /periode sekundær",
          value: "subsequent_period_duration",
          updatable: true,
          required: true,
          type: "string",
        },
        {
          text: "Prisfaktor sekundær",
          value: "subsequent_price_factor",
          updatable: true,
          required: true,
          type: "string",
        },
        {
          text: "Uferdig samtale Strategi",
          value: "incomplete_call_strategy.description",
          updatable: true,
          required: false,
          type: "select",
          item_text: "description",
          item_value: "id",
          parent: "incomplete_call_strategy",
          parent_id: "incomplete_call_strategy_id",
          items: this.getIncompleteCallStrategies,
        },
        {
          text: "Inform extended period ",
          value: "inform_extended_period",
          updatable: true,
          required: false,
          type: "boolean",
        },
        {
          text: "Belast etter samtale",
          value: "charge_after_call",
          updatable: true,
          required: true,
          type: "boolean",
        },
        {
          text: "Rundet opp",
          value: "round_up_minute",
          updatable: true,
          required: true,
          type: "boolean",
        },
        {
          text: "Trekk før hver periode",
          value: "charge_before_each_period",
          updatable: true,
          required: true,
          type: "boolean",
        },
        {
          text: "Charge frekvens",
          value: "charge_frequency_recurring",
          updatable: true,
          required: true,
          type: "string",
        },
        { text: "Handling", value: "actions", sortable: false, align: "end" },
      ];
    },
    getChargeMomentsHeaders() {
      return [
      {
          text: "ID",
          align: "start",
          sortable: true,
          value: "id",
          updatable: false,
        },
        {
          text: "Opprettet",
          align: "start",
          sortable: true,
          value: "created_at",
        },
        {
          text: "Beskrivelse",
          value: "description",
          updatable: true,
          required: true,
          type: "string",
        },
        { text: "Handling", value: "actions", sortable: false, align: "end" },
      ];
    },
    getIncompleteCallStrategiesHeaders() {
      return [
      {
          text: "ID",
          align: "start",
          sortable: true,
          value: "id",
          updatable: false,
        },
        {
          text: "Opprettet",
          align: "start",
          sortable: true,
          value: "created_at",
        },
        {
          text: "Beskrivelse",
          value: "description",
          updatable: true,
          required: true,
          type: "string",
        },
        {
          text: "Varighet i dager",
          value: "balance_limit_days",
          updatable: true,
          required: false,
          type: "string",
        },
        {
          text: "Balansereset time (1-24)",
          value: "balance_limit_hour_of_day",
          updatable: true,
          required: false,
          type: "string",
        },
        {
          text: "Refunder",
          value: "refund",
          updatable: true,
          required: false,
          type: "boolean",
        },
        { text: "Handling", value: "actions", sortable: false, align: "end" },
      ];
    },
    getIntegrationHeaders() {
      return [
      {
          text: "ID",
          align: "start",
          sortable: true,
          value: "id",
          updatable: false,
        },
        {
          text: "Opprettet",
          align: "start",
          sortable: true,
          value: "created_at",
        },
        {
          text: "Identifier",
          value: "identifier",
          updatable: true,
          required: true,
          type: "string",
        },
        {
          text: "Beskrivelse",
          value: "description",
          updatable: true,
          required: false,
          type: "string",
        },
        {
          text: "Provider",
          value: "provider.company_name",
          updatable: true,
          required: false,
          type: "select",
          item_text: "company_name",
          item_value: "id",
          parent: "provider",
          parent_id: "provider_id",
          items: this.getProviders,
        },
        {
          text: "Auth1",
          value: "authtoken1",
          updatable: true,
          required: false,
          type: "string",
        },
        {
          text: "Auth2",
          value: "authtoken2",
          updatable: true,
          required: false,
          type: "string",
        },
        { text: "Handling", value: "actions", sortable: false, align: "end" },
      ];
    },
    getOriginHeaders() {
      return [
      {
          text: "ID",
          align: "start",
          sortable: true,
          value: "id",
          updatable: false,
        },
        {
          text: "Opprettet",
          align: "start",
          sortable: true,
          value: "created_at",
        },
        {
          text: "Beskrivelse",
          value: "description",
          updatable: true,
          required: true,
          type: "string",
        },
        {
          text: "Prefix",
          value: "number_prefix",
          updatable: true,
          required: false,
          type: "string",
        },
        {
          text: "Pris/min",
          value: "price_per_minute",
          updatable: true,
          required: true,
          type: "amount",
        },
        {
          text: "Pris/samtale",
          value: "price_per_call",
          updatable: true,
          required: true,
          type: "amount",
        },

        {
          text: "Handling",
          value: "actions",
          sortable: false,
          updatable: false,
          align: "end",
        },
      ];
    },
    getProviderHeaders() {
      return [
      {
          text: "ID",
          align: "start",
          sortable: true,
          value: "id",
          updatable: false,
        },
        {
          text: "Opprettet",
          align: "start",
          sortable: true,
          value: "created_at",
        },
        {
          text: "Firmanavn",
          value: "company_name",
          updatable: true,
          required: true,
          type: "string",
        },
        {
          text: "Kontakt",
          value: "contact_person",
          updatable: true,
          required: false,
          type: "string",
        },
        {
          text: "Telefon",
          value: "contact_number",
          updatable: true,
          required: false,
          type: "string",
        },
        {
          text: "Common ID",
          value: "common_id",
          updatable: true,
          required: true,
          type: "string",
        },
        {
          text: "Epost",
          value: "contact_email",
          updatable: true,
          required: false,
          type: "email",
        },
        { text: "Handling", value: "actions", sortable: false, align: "end" },
      ];
    },
    getUserHeaders() {
      return [
      {
          text: "ID",
          align: "start",
          sortable: true,
          value: "id",
          updatable: false,
        },
        {
          text: "Opprettet",
          align: "start",
          sortable: true,
          value: "created_at",
        },
        {
          text: "Navn",
          value: "name",
          updatable: true,
          required: true,
          type: "string",
        },
        {
          text: "Epost",
          value: "email",
          updatable: true,
          required: true,
          type: "email",
        },
        {
          text: "Roller",
          value: "roles",
          updatable: true,
          required: false,
          sortable: false,
          type: "multiselect",
          item_text: "name",
          items: this.getRoles,
        },
        {
          text: "Provider",
          value: "provider.company_name",
          updatable: true,
          required: false,
          type: "select",
          item_text: "company_name",
          item_value: "id",
          parent: "provider",
          parent_id: "provider_id",
          items: this.getProviders,
        },
        {
          text: "Kunde",
          value: "customer.customer_name",
          updatable: true,
          required: false,
          type: "select",
          item_text: "customer_name",
          item_value: "id",
          parent: "customer",
          parent_id: "customer_id",
          items: this.getAllCustomers,
          clearable: true,
          align: " d-none",
        },
        { text: "Handling", value: "actions", sortable: false, align: "end" },
      ];
    },
  },
  computed: {
    ...mapGetters("user", [
      "getProfile",
      "getRoles",
      "isAdmin",
      "isSuperAdmin",
    ]),
    ...mapGetters("customer", ["getAllCustomers"]),
    ...mapGetters("provider", ["getProviders"]),
    ...mapGetters("message", ["getMessages"]),
    ...mapGetters("incomplete_call_strategy", ["getIncompleteCallStrategies"]),
  },
  mounted() {
    this.$store.dispatch("user/get_roles").catch((err) => {
      this.$store.dispatch("alert/show", {
        message: `Feil i user/get_roles: ${err.message}`,
        type: "error",
      });
    });
    this.$store.dispatch("customer/get_all").catch((err) => {
      this.$store.dispatch("alert/show", {
        message: `Feil i customer/get: ${err.message}`,
        type: "error",
      });
    });
    this.$store.dispatch("incomplete_call_strategy/get").catch((err) => {
      this.$store.dispatch("alert/show", {
        message: `Feil i incomplete_call_strategy/get: ${err.message}`,
        type: "error",
      });
    });
    this.$store.dispatch("message/get");
    this.$store.dispatch("provider/get").catch((err) => {
      this.$store.dispatch("alert/show", {
        message: `Feil i provider/get: ${err.message}`,
        type: "error",
      });
    });
  },
};
</script>
