<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-6">
          <v-toolbar color="primary" flat dark>
            <v-toolbar-title>Velg nytt passord</v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-card-text>
            <v-form v-model="isValid">
              <v-text-field
                v-model="email"
                label="Epost"
                name="epost"
                prepend-icon="person"
                type="email"
                :rules="[requiredRule('Epost', true), emailRule()]"
              />
              <v-text-field
                type="password"
                v-model="password"
                label="Passord"
                prepend-icon="lock"
                :rules="[requiredRule('Passord', true), passwordLengthRule(8)]"
                error-count="2"
              ></v-text-field>
              <v-text-field
                type="password"
                v-model="password_confirmation"
                label="Bekreft passord"
                prepend-icon="lock"
                :rules="[
                  requiredRule('Bekreft passord', true),
                  v => v === password || 'Passordet matcher ikke'
                ]"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" :disabled="!isValid" @click="resetPassword()"
              >Endre passord</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { settings } from "@/settings";
import validationRules from "@/validation/rules.js";

export default {
  data() {
    return {
      email: this.$route.params.email,
      password: null,
      password_confirmation: null,
      isValid: true
    };
  },
  methods: {
    resetPassword() {
      this.$store
        .dispatch("auth/password_reset", {
          email: this.email,
          password: this.password,
          password_confirmation: this.password_confirmation,
          token: this.$route.params.token
        })
        .then(res => {
          this.$store.dispatch("alert/show", {
            message: `Passord oppdatert. Du kan nå forsøke å logge inn`,
            type: "info"
          });
        })
        .catch(error => {
          this.$store.dispatch("alert/show", {
            message: `Feil i auth/password_reset`,
            type: "error"
          });
        });
    },
    ...validationRules
  }
};
</script>
