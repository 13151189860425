<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card class="elevation-1 mt-4">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title>{{ getProfile.name }}</v-toolbar-title>
          </v-toolbar>

          <data-table
            :headers="getDepartmentHeaders()"
            :get_action="`department/get_by_customer`"
            :update_action="`department/update`"
            :parent_id="getProfile.customer_id"
            :get_department_id="getDepartmentId"
            :show_select="true"
            title="Velg avdeling"
            :singular="`avdeling`"
          >
          </data-table>
          <v-tabs v-model="tab">
            <v-tab>Ordrearkiv</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <orders-table
                :items="orders"
                :headers="orders_headers"
              ></orders-table>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "profile",
  data: () => ({
    title: "profil",
    tab: null,
    department_id: 0,
    orders: [],
    orders_headers: [
      {
        text: "Opprettet",
        align: "start",
        sortable: true,
        value: "created_at"
      },
      {
        text: "Ordredato",
        value: "order_date"
      },
      {
        text: "Beskrivelse",
        value: "description"
      },
      {
        text: "Totalbeløp",
        value: "amount",
        align: "end"
      }
    ]
  }),
  methods: {
    getDepartmentId($event) {
      if ($event && $event[0]) {
        this.department_id = $event[0].id;
        this.getOrders();
      }
    },
    getOrders() {
      this.$store
        .dispatch("order/get", {
          parent_id: this.department_id,
          page: "",
          query: "",
          itemsPerPage: -1,
          sortBy: "",
          sortDesc: ""
        })
        .then(res => {
          this.orders = res.data;
        })
        .catch(err => {
          this.$store.dispatch("alert/show", {
            message: `Feil i order/get: ${err.message}`,
            type: "error"
          });
        });
    },
    getDepartmentHeaders() {
      return [
        {
          text: "Opprettet",
          align: "start",
          sortable: true,
          value: "created_at",
          updatable: false
        },
        {
          text: "Avdelingsnavn",
          value: "name",
          updatable: true,
          required: true,
          type: "string"
        },
        {
          text: "Addresse",
          value: "billing_address",
          updatable: true,
          required: true,
          type: "textfield"
        },
        {
          text: "Postnummer",
          value: "zip_code",
          updatable: true,
          required: true,
          type: "string"
        },
        {
          text: "By",
          value: "city",
          updatable: true,
          required: true,
          type: "string"
        },
        {
          text: "Land",
          value: "country",
          updatable: true,
          required: true,
          type: "string"
        },
        {
          text: "Kontakt",
          value: "contact_person",
          updatable: true,
          required: false,
          type: "string"
        },
        {
          text: "Telefon",
          value: "contact_number",
          updatable: true,
          required: false,
          type: "phone"
        },
        {
          text: "Handling",
          value: "actions",
          sortable: false,
          updatable: false,
          align: "end"
        }
      ];
    }
  },
  computed: {
    ...mapGetters("user", ["getProfile"])
  }
};
</script>
